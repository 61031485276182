import axios from "axios";
import { getToken, getUserDetails, setPreviousUrl, setToken, setUserDetails } from "../../utils/helpers";

export const baseURL =
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST_URL : process.env.REACT_APP_HOST_URL_LIVE;

const axiosInstance = axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${getToken()}` },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const newConfig = config;
    newConfig.headers = { Authorization: `Bearer ${getToken()}` };
    return newConfig;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // First try refreshing token and trying again
    const originalConfig = error.config;
    const { url, isRetry } = originalConfig;
    if (!url.includes("refreshSession") && error.response) {
      // Token was expired and it wasn't a refresh session request
      if (error.response.status === 401 && !isRetry) {
        originalConfig.isRetry = true;
        try {
          const { clientId, refreshToken } = getUserDetails();
          const resp = await axiosInstance.post(
            "/v1/refreshSession",
            { refreshToken, clientId },
            { headers: { Authorization: `Bearer ${getToken()}` } }
          );
          const { token, refreshToken: newRefreshToken, client } = resp.data;
          setToken(token);
          setUserDetails({ clientId: client.clientId, refreshToken: newRefreshToken });
        } catch (err) {
          if (err?.response?.status === 401 || err?.response?.status === 404) {
            setPreviousUrl(window.location);
            window.logoutUser();
          }
          Promise.reject(err);
        }
      }
    } else if (url.includes("refreshSession")) {
      setPreviousUrl(window.location);
      window.logoutUser();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
