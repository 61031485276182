import { blueGrey, orange, red, blue } from "@mui/material/colors";

const { createTheme } = require("@mui/material");

const DefaultTheme = createTheme({
  palette: {
    primary: {
      main: orange[900],
    },
    secondary: {
      main: blue[500],
    },
    background: {
      default: blueGrey[100],
      card: "white",
      appBar: "white",
      sideNav: "#182838",
    },
  },
  status: {
    danger: red[500],
  },
  typography: {
    fontFamily: "Roboto",
    h6: {
      fontWeight: "bolder",
    },
    h4: {
      fontWeight: "bolder",
    },
    body2: {
      fontSize: ".8rem",
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingBottom: 16,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "1rem",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: "#1e3853",
            color: orange[900],
          },
          "&.Mui-selected": {
            color: orange[900],
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: ".6rem",
        },
      },
    },
  },
});

export default DefaultTheme;
