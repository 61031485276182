/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Router from "./router";
import "./App.css";
import store from "./redux/store";
import MyThemeProvider from "./themes/MyThemeProvider";

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <SnackbarProvider>
          <MyThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Router />
            </LocalizationProvider>
          </MyThemeProvider>
        </SnackbarProvider>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
