import React, { useEffect, useState } from "react";
import {
  Badge,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import AutorenewTwoToneIcon from "@mui/icons-material/AutorenewTwoTone";
import DoneAllTwoToneIcon from "@mui/icons-material/DoneAllTwoTone";
import DoDisturbOffTwoToneIcon from "@mui/icons-material/DoDisturbOffTwoTone";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchBackgroundTasks } from "../../redux/campaignSlice";
import { fNormDate } from "../../utils/formaters";
import UserAvatar from "../../components/UserAvatar";

function AvatarMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const { backgroundTasks } = useSelector((state) => state.campaign);
  const [hasOpened, setOpened] = useState(false);

  const avatarMenuOpen = Boolean(anchorEl);
  const hasPendingOperations = backgroundTasks.filter((operation) => operation.status === "PENDING").length > 0;

  useEffect(() => {
    dispatch(fetchBackgroundTasks());
  }, []);

  const onLogout = () => {
    window.logoutUser();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpened(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack onClick={handleClick} sx={{ ml: "auto", cursor: "pointer" }}>
        {!hasOpened && (
          <Badge color="secondary" overlap="circular" badgeContent={backgroundTasks.length}>
            <UserAvatar size={40} />
          </Badge>
        )}
        {hasOpened && <UserAvatar size={40} />}
        {hasPendingOperations && <CircularProgress sx={{ position: "absolute" }} color="secondary" />}
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={avatarMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {backgroundTasks.length > 10 && <Typography sx={{ mx: 2, my: 1 }}>Top 10 notifications</Typography>}
        {backgroundTasks.slice(0, 10).map(({ operationId, description, status, createTimestamp, durationSeconds }) => (
          <MenuItem
            key={operationId}
            onClick={() => {
              navigate("/dashboard/long-runnings");
              handleClose();
            }}
          >
            {status === "PENDING" && (
              <ListItemIcon>
                <AutorenewTwoToneIcon
                  fontSize="small"
                  sx={{
                    animation: "spin 3s linear infinite",
                    "@keyframes spin": {
                      "0%": {
                        transform: "rotate(-360deg)",
                      },
                      "100%": {
                        transform: "rotate(0deg)",
                      },
                    },
                  }}
                />
              </ListItemIcon>
            )}
            {status === "COMPLETED" && (
              <ListItemIcon>
                <DoneAllTwoToneIcon fontSize="small" />
              </ListItemIcon>
            )}
            {status === "CANCELED" && (
              <ListItemIcon>
                <DoDisturbOffTwoToneIcon fontSize="small" />
              </ListItemIcon>
            )}
            {status === "FAILED" && (
              <ListItemIcon>
                <ErrorTwoToneIcon fontSize="small" />
              </ListItemIcon>
            )}
            <ListItemText
              primary={description}
              secondary={`${fNormDate(createTimestamp * 1000)} | ${durationSeconds} s`}
              primaryTypographyProps={{ variant: "body2", fontWeight: "bold" }}
              sx={{ fontSize: ".8rem" }}
            />
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <LogoutTwoToneIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default AvatarMenu;
