import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { baseURL } from "../api/base/axios";

function PortalLogo({ height }) {
  const { host } = useSelector((state) => state.auth);
  return (
    <img
      src={
        host?.clientBrandDetails?.brandLogoPath
          ? `${baseURL}${host?.clientBrandDetails?.brandLogoPath}`
          : "/assets/images/logo_full.png"
      }
      alt="onfon sms logo"
      height={height}
    />
  );
}

PortalLogo.propTypes = {
  height: PropTypes.number,
};

PortalLogo.defaultProps = {
  height: 50,
};

export default PortalLogo;
