/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import onfonSmsApi from "../api/onfonSmsApi";
import { fUtcTimestamp } from "../utils/formaters";

const initialState = {
  senderIds: [],
  contactGroups: [],
  contactGroupsLength: 0,
  contactGroupsTokens: [""],
  contacts: [],
  contactsLength: 0,
  contactsPageTokens: [""],
  smsToDeliver: [],
  smsToDeliverLength: 0,
  smsToDeliverTokens: [""],
  mtMessages: [],
  mtMessagesLength: 0,
  mtMessagesTokens: [""],
  campaigns: [],
  backgroundTasks: [],
  backgroundTasksLength: 0,
  backgroundTasksTokens: [""],
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setSenderIds(state, action) {
      state.senderIds = action.payload;
    },
    setContactGroups(state, action) {
      const { groups, collectionCount, nextPageToken } = action.payload;
      state.contactGroups = groups;
      if (parseInt(collectionCount, 10)) state.contactGroupsLength = parseInt(collectionCount, 10);
      if (state.contactsPageTokens.indexOf(nextPageToken) < 0) state.contactsPageTokens.push(nextPageToken);
    },
    setContacts(state, action) {
      const { contacts, collectionCount, nextPageToken } = action.payload;
      state.contacts = contacts;
      if (parseInt(collectionCount, 10)) state.contactsLength = parseInt(collectionCount, 10);
      if (state.contactsPageTokens.indexOf(nextPageToken) < 0) state.contactsPageTokens.push(nextPageToken);
    },
    setCampaigns(state, action) {
      state.campaigns = action.payload;
    },
    setSmsToDeliver(state, action) {
      const { messages, collectionCount, nextPageToken } = action.payload;
      state.smsToDeliver = messages;
      if (parseInt(collectionCount, 10)) state.smsToDeliverLength = parseInt(collectionCount, 10);
      if (state.smsToDeliverTokens.indexOf(nextPageToken) < 0) state.smsToDeliverTokens.push(nextPageToken);
    },
    setMtMessages(state, action) {
      const { messages, collectionCount, nextPageToken, page = 0 } = action.payload;
      state.mtMessages = messages;
      if (parseInt(collectionCount, 10)) state.mtMessagesLength = parseInt(collectionCount, 10);
      state.mtMessagesTokens[page + 1] = nextPageToken;
    },
    setBackgroundTasks(state, action) {
      const { operations, nextPageToken, collectionCount } = action.payload;
      state.backgroundTasks = operations;
      if (parseInt(collectionCount, 10)) state.backgroundTasksLength = parseInt(collectionCount, 10);
      if (state.backgroundTasksTokens.indexOf(nextPageToken) < 0) state.backgroundTasksTokens.push(nextPageToken);
    },
  },
});

export const {
  setSenderIds,
  setContactGroups,
  setSmsToDeliver,
  setMtMessages,
  setContacts,
  setCampaigns,
  setBackgroundTasks,
} = campaignSlice.actions;

export const fetchSenderIds = () => async (dispatch) => {
  const response = await onfonSmsApi.campaign.senderId.fetchAll();
  const { senderIds } = response.data;
  dispatch(setSenderIds(senderIds));
};

export const addSenderId = (data) => async (dispatch) => {
  try {
    await onfonSmsApi.campaign.senderId.create(data);
    dispatch(fetchSenderIds());
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error?.response.data);
  }
};

export const approveSenderId = (data) => async (dispatch) => {
  try {
    await onfonSmsApi.campaign.senderId.approve(data);
    dispatch(fetchSenderIds());
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error?.response.data);
  }
};

export const delegateSenderId = (data) => async (dispatch) => {
  try {
    const { data: resp } = await onfonSmsApi.campaign.senderId.delegate(data);
    dispatch(fetchSenderIds());
    return Promise.resolve(resp);
  } catch (error) {
    return Promise.reject(error?.response?.data);
  }
};

export const fetchContactGroups = (filter) => async (dispatch, getState) => {
  let filterQuery = "";
  if (filter) {
    filterQuery += "?";
    const { pageSize, prevPage, nextPage, contactGroupId, page = 0 } = filter;
    if (contactGroupId) {
      filterQuery += `filter.groupIds=${contactGroupId}&`;
    }
    if (pageSize) {
      filterQuery += `pageSize=${pageSize}&`;
    }
    if (nextPage) {
      filterQuery += `pageToken=${getState().campaign.contactsPageTokens[page]}&`;
    }
    if (prevPage) {
      filterQuery += `pageToken=${getState().campaign.contactsPageTokens[page]}&`;
    }
  }
  const response = await onfonSmsApi.campaign.contactGroups.fetchAll(filterQuery);
  const { groups, collectionCount, nextPageToken } = response.data;
  dispatch(setContactGroups({ groups, collectionCount, nextPageToken }));
};

export const fetchContactGroup = (id) => async () => {
  const response = await onfonSmsApi.campaign.contactGroups.fetch(id);
  return response.data;
};

export const addContactGroup = (data) => async (dispatch) => {
  await onfonSmsApi.campaign.contactGroups.create(data);
  dispatch(fetchContactGroups());
};

export const fetchContacts = (filter) => async (dispatch, getState) => {
  let filterQuery = "";
  if (filter) {
    filterQuery += "?";
    const { pageSize, contactGroupId, page = 0 } = filter;
    if (contactGroupId) {
      filterQuery += `filter.groupIds=${contactGroupId}&`;
    }
    if (pageSize) {
      filterQuery += `pageSize=${pageSize}&`;
    }
    if (page) {
      filterQuery += `pageToken=${getState().campaign.contactsPageTokens[page]}&`;
    }
  }
  const response = await onfonSmsApi.campaign.contacts.fetchAll(filterQuery);
  const { contacts, collectionCount, nextPageToken } = response.data;
  dispatch(setContacts({ contacts, collectionCount, nextPageToken }));
};

export const addContact = (data) => async (dispatch) => {
  await onfonSmsApi.campaign.contacts.create(data);
  dispatch(fetchContacts());
};

export const deleteContact = (contactId) => async () => {
  await onfonSmsApi.campaign.contacts.delete(contactId);
};

export const importContactsFromFile = (data) => async (dispatch) => {
  await onfonSmsApi.campaign.contacts.importFromFile(data);
  dispatch(fetchContacts());
};

export const fetchCampaigns = () => async (dispatch) => {
  const response = await onfonSmsApi.campaign.campaigns.fetchAll();
  const { campaigns } = response.data;
  dispatch(setCampaigns(campaigns));
};

export const fetchCampaign = (id) => async () => {
  const response = await onfonSmsApi.campaign.campaigns.fetch(id);
  const campaign = response.data;
  return campaign;
};

export const fetchSmsToDeliver = (filter) => async (dispatch, getState) => {
  let filterQuery = "";
  if (filter) {
    filterQuery += "?";
    const { campaignId, pageSize, page = 0, fromDate, toDate } = filter;
    if (campaignId) {
      filterQuery += `filter.campaignIds=${campaignId}&`;
    }
    if (pageSize) {
      filterQuery += `pageSize=${pageSize}&`;
    }
    if (page) {
      filterQuery += `pageToken=${getState().campaign.smsToDeliverTokens[page]}&`;
    }
    if (fromDate) {
      filterQuery += `filter.startTimestamp=${fUtcTimestamp(fromDate)}`;
    }
    if (toDate) {
      filterQuery += `filter.startTimestamp=${fUtcTimestamp(toDate)}`;
    }
  }
  const response = await onfonSmsApi.campaign.sms.fetchAllToDeliver(filterQuery);
  const { messages, collectionCount, nextPageToken } = response.data;
  dispatch(setSmsToDeliver({ messages, collectionCount, nextPageToken }));
};

export const fetchMtMessages = (filter) => async (dispatch, getState) => {
  let filterQuery = "";
  if (filter) {
    filterQuery += "?";
    const { pageSize, page, destinations, from, to } = filter;
    if (pageSize) {
      filterQuery += `pageSize=${pageSize}&`;
    }
    if (page) {
      filterQuery += `pageToken=${getState().campaign.mtMessagesTokens[page]}&`;
    }
    if (destinations) {
      filterQuery += `filter.destinations=${destinations}&`;
    }
    if (from) {
      filterQuery += `filter.startTimestamp=${fUtcTimestamp(from)}&`;
    }
    if (to) {
      const endOfDay = new Date(to);
      endOfDay.setUTCHours(23, 59, 59, 999);
      filterQuery += `filter.endTimestamp=${fUtcTimestamp(endOfDay)}&`;
    }
  }
  const response = await onfonSmsApi.campaign.sms.fetchMtMessages(filterQuery);
  const { messages, collectionCount, nextPageToken } = response.data;
  dispatch(setMtMessages({ messages, collectionCount, nextPageToken, page: filter?.page }));
};

export const sendFromFile1 = (data) => async () => {
  try {
    const response = await onfonSmsApi.campaign.fromFile.step1(data);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const sendFromFile2 = (data) => async () => {
  try {
    const response = await onfonSmsApi.campaign.fromFile.step2(data);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error?.response.data.error);
  }
};

export const sendFromFile3 = (data) => async () => {
  try {
    const response = await onfonSmsApi.campaign.fromFile.step3(data);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchBackgroundTasks = (filter) => async (dispatch, getState) => {
  try {
    let filterQuery = "";
    if (filter) {
      filterQuery += "?";
      const { pageSize, page } = filter;
      if (pageSize) {
        filterQuery += `pageSize=${pageSize}&`;
      }
      if (page) {
        filterQuery += `pageToken=${getState().campaign.backgroundTasksTokens[page]}&`;
      }
    }
    const response = await onfonSmsApi.campaign.backgroundTasks.fetchAll(filterQuery);
    const { operations, nextPageToken, collectionCount } = response.data;
    dispatch(setBackgroundTasks({ operations, nextPageToken, collectionCount }));
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error?.response.data);
  }
};

export default campaignSlice.reducer;
