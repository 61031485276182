import React, { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Avatar } from "@mui/material";
import { baseURL } from "../api/base/axios";
import { getToken } from "../utils/helpers";

function UserAvatar({ size }) {
  const {
    user: { profileUrl, clientName },
  } = useSelector((state) => state.auth);
  const token = getToken();

  return (
    <Avatar sx={{ width: size, height: size }} alt="profile pic" src={`${baseURL}${profileUrl}?token=${token}`}>
      {clientName.charAt(0)}
    </Avatar>
  );
}

UserAvatar.propTypes = {
  size: PropTypes.number,
};

UserAvatar.defaultProps = {
  size: 50,
};

export default UserAvatar;
