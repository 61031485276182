import { blueGrey, blue, red } from "@mui/material/colors";

const { createTheme } = require("@mui/material");

const CobaltTheme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: red[700],
    },
    background: {
      default: blueGrey[100],
      card: "white",
      appBar: "white",
      sideNav: "#182838",
    },
  },
  status: {
    danger: red[500],
  },
  typography: {
    fontFamily: "Roboto",
    h6: {
      fontWeight: "bolder",
    },
    h4: {
      fontWeight: "bolder",
    },
    body2: {
      fontSize: ".8rem",
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingBottom: 16,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#1e3853",
            color: blue[500],
          },
          "&.Mui-selected": {
            color: blue[500],
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
  },
});

export default CobaltTheme;
