import React, { createContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { getThemeName, setThemeName } from "../utils/helpers";
import getThemeByName from "./base";

export const MyThemeContext = createContext(() => {});

function MyThemeProvider({ children }) {
  const [themeName, _setThemeName] = useState(getThemeName());
  const theme = getThemeByName(themeName);

  const setTheme = (val) => {
    _setThemeName(val);
    setThemeName(val);
  };

  return (
    <MyThemeContext.Provider value={useMemo(() => ({ setTheme, themeName }), [themeName])}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </MyThemeContext.Provider>
  );
}

MyThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MyThemeProvider;
