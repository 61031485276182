/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import onfonSmsApi from "../api/onfonSmsApi";

const initialState = {
  purchases: [],
};

const purchaseSlice = createSlice({
  name: "jasmin",
  initialState,
  reducers: {
    setPurchases(state, action) {
      state.purchases = action.payload;
    },
  },
});

export const { setPurchases } = purchaseSlice.actions;

export const fetchPurchases = () => async (dispatch) => {
  const response = await onfonSmsApi.purchase.fetchAll();
  const { purchases } = response.data;
  dispatch(setPurchases(purchases));
};

export const addPurchase = (data) => async (dispatch) => {
  try {
    await onfonSmsApi.purchase.create(data);
    dispatch(fetchPurchases());
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error?.response?.data);
  }
};

export const sendTopupStk = (data) => async () => {
  try {
    const response = await onfonSmsApi.purchase.sendStk(data);
    return response.data;
  } catch (error) {
    return Promise.reject(error?.response?.data);
  }
};

export default purchaseSlice.reducer;
