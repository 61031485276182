import { del, get, post, put } from "./base";

const onfonSmsApi = {
  brand: {
    getHostBranding: () => get("/v1/hostbranding"),
  },
  user: {
    login: (data) => post("/v1/signIn", data),
    refreshSession: (data) => post("/v1/refreshSession", data),
    fetchById: (id) => get(`/v1/onfonapps/clients/${id}`),
    register: (data) => post("/v1/clients", data),
    join: (data) => post("/v1/joinUser", data),
    requestAccountActivation: (data) => post("/v1/requestActivateAccountOTP", data),
    fetchClients: () => get("/v1/onfonapps/clients"),
    inviteClient: (data) => post("/v1/onfonapps/clients:inviteClient", data),
    resendInvite: (data) => post(`v1/onfonapps/clients:resendInvitation`, data),
    fetchClient: (id) => get(`/v1/onfonapps/clients/${id}?privilege=true`),
    editClient: (id, data) => put(`/v1/onfonapps/clients/${id}`, data),
    changePassword: (data) => post("/v1/onfonapps/clients:updatePassword", data),
    profileUpload: (data) => post("/v1/onfonapps/profileuploads", data),
    requestChangePassword: (data) => post("/v1/requestChangePassword", data),
    changePasswordExt: (data) => post("/v1/onfonapps/clients:updatePasswordExternal", data),
  },
  campaign: {
    senderId: {
      fetchAll: () => get("/v1/onfonapps/senderids"),
      create: (data) => post("/v1/onfonapps/senderids", data),
      approve: (data) => post("/v1/onfonapps/senderids:approveSenderId", data),
      delegate: (data) => post("/v1/onfonapps/senderids:delegateSenderId", data),
    },
    contactGroups: {
      fetchAll: () => get("/v1/onfonapps/contactgroups"),
      fetch: (id) => get(`/v1/onfonapps/contactgroups/${id}`),
      create: (data) => post("/v1/onfonapps/contactgroups", data),
    },
    contacts: {
      fetchAll: (filter) => get(`/v1/onfonapps/contacts${filter}`),
      create: (data) => post("/v1/onfonapps/contacts", data),
      importFromFile: (data) => post("/v1/onfonapps/contacts:uploadContact", data),
      delete: (id) => del(`/v1/onfonapps/contacts/${id}`),
    },
    sms: {
      compose: (data) => post("/v1/onfonapps/sms:compose", data),
      send: (data) => post("/v1/onfonapps/sms:sendCampaign", data),
      fetchAllToDeliver: (filter) => get(`/v1/onfonapps/sms/todeliver${filter}`),
      fetchMtMessages: (query) => get(`/v1/onfonapps/sms/mt${query}`),
    },
    campaigns: {
      fetchAll: () => get("/v1/onfonapps/campaigns"),
      fetch: (id) => get(`/v1/onfonapps/campaigns/${id}`),
    },
    fromFile: {
      step1: (data) => post("/v1/onfonapps/sms:sendFromFileStep1", data),
      step2: (data) => post("/v1/onfonapps/sms:sendFromFileStep2", data),
      step3: (data) => post("/v1/onfonapps/sms:sendCampaign", data),
    },
    backgroundTasks: {
      fetchAll: (filter) => get(`/v1/longrunning?${filter}`),
    },
  },
  jasmin: {
    groups: {
      fetchAll: () => get("/v1/onfonapps/jasmin/groups"),
      create: (data) => post("/v1/onfonapps/jasmin/groups", data),
    },
    users: {
      fetchAll: () => get("/v1/onfonapps/jasmin/users"),
      create: (data) => post("/v1/onfonapps/jasmin/users", data),
    },
    smpp: {
      fetchAll: () => get("/v1/onfonapps/jasmin/smpp-connectors"),
      create: (data) => post("/v1/onfonapps/jasmin/smpp-connectors", data),
    },
    http: {
      fetchAll: () => get("/v1/onfonapps/jasmin/http-connectors"),
      create: (data) => post("/v1/onfonapps/jasmin/http-connectors", data),
    },
    generic: {
      fetchAll: () => get("/v1/onfonapps/jasmin/generic-connectors"),
      create: (data) => post("/v1/onfonapps/jasmin/generic-connectors", data),
    },
    filter: {
      fetchAll: () => get("/v1/onfonapps/route-filters"),
      create: (data) => post("/v1/onfonapps/route-filters", data),
      fetchFilterTypes: () => get("/v1/onfonapps/route-filter-types"),
    },
    mo: {
      fetchAll: () => get("/v1/onfonapps/routes/mo"),
      create: (data) => post("/v1/onfonapps/routes/mo", data),
    },
    mt: {
      fetchAll: () => get("/v1/onfonapps/routes/mt"),
      create: (data) => post("/v1/onfonapps/routes/mt", data),
    },
  },
  purchase: {
    fetchAll: () => get("/v1/onfonapps/purchases"),
    create: (data) => post("/v1/onfonapps/purchases", data),
    sendStk: (data) => post("/v1/onfonapps/payments:sendStk", data),
  },
  contacts: {
    fetchAll: () => get("/v1/onfonapps/contacts"),
    create: (data) => post("/v1/onfonapps/contacts", data),
  },
  stats: {
    fetchNetworkStats: (filter) => get(`/v1/onfonapps/stats/network${filter}`),
    fetchDeliveryStats: (filter) => get(`/v1/onfonapps/stats/dlr${filter}`),
    fetchCountryStats: (filter) => get(`/v1/onfonapps/stats/country${filter}`),
  },
};

export default onfonSmsApi;
