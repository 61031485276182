import DefaultTheme from "./defaultTheme";
import DefaultDarkTheme from "./defaultDark";
import AmberTheme from "./amberTheme";
import CobaltTheme from "./cobaltTheme";
import AmberDarkTheme from "./amberDark";
import CobaltDarkTheme from "./cobaltDark";

const themeMap = {
  default: DefaultTheme,
  defaultDark: DefaultDarkTheme,
  amber: AmberTheme,
  amberDark: AmberDarkTheme,
  cobalt: CobaltTheme,
  cobaltDark: CobaltDarkTheme,
};

export default function getThemeByName(theme) {
  return themeMap[theme];
}
