import React from "react";
import { Box, CircularProgress } from "@mui/material";

function LoadingScreen() {
  return (
    <Box sx={{ display: "flex", minHeight: "95vh" }}>
      <CircularProgress sx={{ margin: "auto" }} />
    </Box>
  );
}

export default LoadingScreen;
