/* eslint-disable react/jsx-filename-extension */
import { useSnackbar } from "notistack";
import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "./layout/DashboardLayout";
import LoadingScreen from "./pages/LoadingScreen";
import { fetchHostDetails, refreshSession, setUser } from "./redux/authSlice";
import { setSenderIds } from "./redux/campaignSlice";
import { getUserDetails, setUserDetails } from "./utils/helpers";

function Loadable(Component) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component />
    </Suspense>
  );
}

const Login = Loadable(lazy(() => import("./pages/Auth/Login")));
const Register = Loadable(lazy(() => import("./pages/Auth/Register")));
const Join = Loadable(lazy(() => import("./pages/Auth/Join")));
const ActivateAccount = Loadable(lazy(() => import("./pages/Auth/ActivateAccount")));
const ForgotPassword = Loadable(lazy(() => import("./pages/Auth/ForgotPassword")));
const ResetPassword = Loadable(lazy(() => import("./pages/Auth/ResetPassword")));
// Dashboard Pages
const MainDashboard = Loadable(lazy(() => import("./pages/Dashboard/MainDashboard")));
// Campaign Pages
const SendSMS = Loadable(lazy(() => import("./pages/Campaigns/SendSMS")));
const SendFromFile = Loadable(lazy(() => import("./pages/Campaigns/SendFromFile")));
const Campaigns = Loadable(lazy(() => import("./pages/Campaigns/Campaigns")));
const CampaignMessages = Loadable(lazy(() => import("./pages/Campaigns/CampaignMessages")));
const CampaignSentMessages = Loadable(lazy(() => import("./pages/Campaigns/SentMessages")));
// Purchase Page
const Purchase = Loadable(lazy(() => import("./pages/Purchase/Purchases")));
const TopUp = Loadable(lazy(() => import("./pages/Purchase/TopUp")));
// Jasmin Pages
const JasminGroups = Loadable(lazy(() => import("./pages/Jasmin/Groups")));
const JasminUsers = Loadable(lazy(() => import("./pages/Jasmin/Users")));
const JasminHttp = Loadable(lazy(() => import("./pages/Jasmin/Http")));
const JasminSmpp = Loadable(lazy(() => import("./pages/Jasmin/Smpp")));
const JasminGeneric = Loadable(lazy(() => import("./pages/Jasmin/Generic")));
const JasminFilters = Loadable(lazy(() => import("./pages/Jasmin/Filters")));
const JasminMoRoutes = Loadable(lazy(() => import("./pages/Jasmin/MoRoutes")));
const JasminMtRoutes = Loadable(lazy(() => import("./pages/Jasmin/MtRoutes")));
// COntacts Page
const ContactGroups = Loadable(lazy(() => import("./pages/Contacts/ContactGroups")));
const ManageContactGroup = Loadable(lazy(() => import("./pages/Contacts/ManageContactGroup")));
const Contacts = Loadable(lazy(() => import("./pages/Contacts/Contacts")));
// Sender IDs
const SenderIds = Loadable(lazy(() => import("./pages/SenderId/SenderIds")));
//  Clients
const Clients = Loadable(lazy(() => import("./pages/Auth/Clients")));
const ViewClient = Loadable(lazy(() => import("./pages/Auth/ViewClient")));
// Other Pages
const Settings = Loadable(lazy(() => import("./pages/Auth/Settings")));
const BackgroundTasks = Loadable(lazy(() => import("./pages/BackgroundTasks/BackgroundTasks")));
const TestPage = Loadable(lazy(() => import("./pages/Test/MainTest")));

function Router() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user, isInitialized } = useSelector((state) => state.auth);

  // Allows for axios to call function
  window.logoutUser = () => {
    setUserDetails(null);
    dispatch(setUser(null));
    dispatch(setSenderIds([]));
    enqueueSnackbar("You have been logged out", { variant: "error", autoHideDuration: 15000 });
  };

  // Allows for axios to call error function
  window.showFatalError = (errorMessage) => {
    enqueueSnackbar(errorMessage, { variant: "error" });
  };

  useEffect(() => {
    // auto login user if present in localstorage
    if (isInitialized) return;
    dispatch(fetchHostDetails());
    const userDetails = getUserDetails();
    if (userDetails?.clientId) {
      // if there is user details in localstorage autologin and dispatch auth
      dispatch(refreshSession());
    } else {
      // otherwise initilize auth with null user
      dispatch(setUser(null));
    }
  }, [dispatch, enqueueSnackbar, isInitialized]);

  return (
    <BrowserRouter>
      <Routes>
        {!isInitialized && <Route path="*" element={<LoadingScreen />} />}
        {!user && (
          <>
            <Route exact path="/" element={Login} />
            <Route exact path="/register" element={Register} />
            <Route exact path="/join" element={Join} />
            <Route exact path="/activate" element={ActivateAccount} />
            <Route exact path="/forgot-password" element={ForgotPassword} />
            <Route exact path="/reset-password" element={ResetPassword} />
            <Route replace path="*" element={<Navigate to="/" />} />
          </>
        )}
        {/* Auth protected routes */}
        {!!user && (
          <>
            <Route path="dashboard" element={user ? <DashboardLayout /> : <Navigate to="/" replace />}>
              <Route path="" element={MainDashboard} />
              {/* Campaign */}
              <Route path="campaigns/send-sms" element={SendSMS} />
              <Route path="campaigns/from-file" element={SendFromFile} />
              <Route path="campaigns" element={Campaigns} />
              <Route path="campaigns/campaign-messages" element={CampaignMessages} />
              <Route path="campaigns/sent-messages" element={CampaignSentMessages} />
              {/* Jasmin */}
              <Route path="jasmin/groups" element={JasminGroups} />
              <Route path="jasmin/users" element={JasminUsers} />
              <Route path="jasmin/smpp" element={JasminSmpp} />
              <Route path="jasmin/http" element={JasminHttp} />
              <Route path="jasmin/generic" element={JasminGeneric} />
              <Route path="jasmin/filters" element={JasminFilters} />
              <Route path="jasmin/mo-routes" element={JasminMoRoutes} />
              <Route path="jasmin/mt-routes" element={JasminMtRoutes} />
              {/* Contacts and Groups */}
              <Route path="contact-groups" element={ContactGroups} />
              <Route path="manage-contact-group/:contactGroupId" element={ManageContactGroup} />
              <Route path="contacts" element={Contacts} />
              {/* Purchase */}
              <Route path="purchases" element={Purchase} />
              <Route path="purchases/topup" element={TopUp} />
              {/* Sender IDs */}
              <Route path="sender-ids" element={SenderIds} />
              {/* Clients */}
              <Route path="clients" element={Clients} />
              <Route path="clients/view/:clientId" element={ViewClient} />
              {/* Other */}
              <Route path="settings" element={Settings} />
              <Route path="long-runnings" element={BackgroundTasks} />
              {/* Other */}
              <Route path="test" element={TestPage} />
            </Route>
            <Route path="*" replace element={<Navigate to="/dashboard" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
