import React, { useState } from "react";
import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CampaignTwoToneIcon from "@mui/icons-material/CampaignTwoTone";
import ArchitectureTwoToneIcon from "@mui/icons-material/ArchitectureTwoTone";
import RecentActorsTwoToneIcon from "@mui/icons-material/RecentActorsTwoTone";
import FingerprintTwoToneIcon from "@mui/icons-material/FingerprintTwoTone";
import RefreshIcon from "@mui/icons-material/Refresh";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import AccountBalanceWalletTwoToneIcon from "@mui/icons-material/AccountBalanceWalletTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import IntegrationInstructionsTwoToneIcon from "@mui/icons-material/IntegrationInstructionsTwoTone";
import CloudSyncTwoToneIcon from "@mui/icons-material/CloudSyncTwoTone";
import { fNumWithCommas } from "../utils/formaters";
import { refreshSession } from "../redux/authSlice";
import UserAvatar from "../components/UserAvatar";

const currentYear = new Date().getFullYear();
const DOCS_URL = process.env.REACT_APP_DOCUMENTATION;

const routes = [
  {
    key: "dash",
    path: "/dashboard",
    label: "Dashboard",
    icon: <DashboardIcon />,
    roles: ["ALL"],
  },
  {
    key: "jas",
    label: "Jasmin",
    icon: <ArchitectureTwoToneIcon />,
    roles: ["ADMINISTRATOR"],
    routes: [
      { key: "j1", path: "/dashboard/jasmin/groups", label: "Jasmin Groups" },
      { key: "j2", path: "/dashboard/jasmin/users", label: "Jasmin Users" },
      { key: "j3", path: "/dashboard/jasmin/smpp", label: "SMPP Connectors" },
      { key: "j4", path: "/dashboard/jasmin/http", label: "HTTP Connectors" },
      { key: "j5", path: "/dashboard/jasmin/generic", label: "Generic Connectors" },
      { key: "j6", path: "/dashboard/jasmin/filters", label: "Jasmin Filters" },
      { key: "j7", path: "/dashboard/jasmin/mo-routes", label: "MO Routes" },
      { key: "j7", path: "/dashboard/jasmin/mt-routes", label: "MT Routes" },
    ],
  },
  {
    key: "camp",
    label: "SMS & Campaigns",
    icon: <CampaignTwoToneIcon />,
    roles: ["ALL"],
    routes: [
      { key: "c1", path: "/dashboard/campaigns/send-sms", label: "Send SMS" },
      { key: "c2", path: "/dashboard/campaigns/from-file", label: "Send From File" },
      { key: "c3", path: "/dashboard/campaigns", label: "Campaigns" },
      { key: "c3", path: "/dashboard/campaigns/campaign-messages", label: "Campaign Messages" },
      { key: "c4", path: "/dashboard/campaigns/sent-messages", label: "Sent Messages" },
    ],
  },
  {
    key: "cont",
    label: "Contacts & Groups",
    icon: <RecentActorsTwoToneIcon />,
    roles: ["ALL"],
    routes: [
      { key: "c2", path: "/dashboard/contact-groups", label: "Contact Groups" },
      { key: "c1", path: "/dashboard/contacts", label: "Contacts" },
    ],
  },

  {
    key: "wal",
    label: "Wallet",
    icon: <AccountBalanceWalletTwoToneIcon />,
    roles: ["ALL"],
    routes: [
      { key: "p1", path: "/dashboard/purchases", label: "Purchases" },
      { key: "p2", path: "/dashboard/purchases/topup", label: "Top Up" },
    ],
  },
  {
    key: "sen",
    path: "/dashboard/sender-ids",
    label: "Sender IDs",
    icon: <FingerprintTwoToneIcon />,
    roles: ["ALL"],
  },
  {
    key: "cli",
    path: "/dashboard/clients",
    label: "Clients",
    icon: <PeopleAltTwoToneIcon />,
    roles: ["ADMINISTRATOR", "RESELLER"],
  },
  {
    key: "bac",
    path: "/dashboard/long-runnings",
    label: "Background Tasks",
    icon: <CloudSyncTwoToneIcon />,
    roles: ["ALL"],
  },
  {
    key: "set",
    path: "/dashboard/settings",
    label: "Settings",
    icon: <SettingsTwoToneIcon />,
    roles: ["ALL"],
  },
  {
    key: "api",
    path: DOCS_URL,
    label: "API Documentation",
    icon: <IntegrationInstructionsTwoToneIcon />,
    roles: ["ALL"],
  },
];

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 1),
  justifyContent: "flex-end",
  height: "fit-content",
}));
function SideNav({ open, onToggleNav, drawerWidth, isMobile }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentLocation = location.pathname;

  const { user } = useSelector((state) => state.auth);

  const currentPathKey = routes.find((el) => el.routes?.some((subEl) => currentLocation.indexOf(subEl.path) > -1))?.key;

  const [expanded, setExpanded] = useState([currentPathKey]);

  const allowedRoutes = routes.filter(
    (route) => route.roles.includes("ALL") || route.roles.includes(user.clientAccessLevel)
  );

  const toggleNav = (key) => {
    if (!expanded.includes(key)) {
      setExpanded([...expanded, key]);
    } else {
      setExpanded(expanded.filter((el) => el !== key));
    }
  };

  const onClickMenu = ({ key, path }) => {
    if (path) {
      if (isMobile) {
        onToggleNav();
      }
      const isExternal = path.startsWith("http");
      if (isExternal) {
        window.open(path, "_blank");
      } else {
        navigate(path);
      }
    } else {
      toggleNav(key);
    }
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: theme.palette.background.sideNav,
          backgroundImage: "none",
          color: "white",
        },
        "> *": {
          overflow: "hidden",
          overflowY: "scroll",
        },
      }}
      variant={isMobile ? "temporary" : "persistent"}
      anchor="left"
      onClose={onToggleNav}
      open={open}
    >
      <DrawerHeader sx={{ my: 1 }}>
        <Stack direction="row" sx={{ width: "100%" }} alignItems="center">
          <Box sx={{ m: 2 }}>
            <UserAvatar size={50} />
          </Box>
          <Stack>
            <Typography variant="h6">
              {user.clientName.length > 13 ? `${user.clientName.substr(0, 11)}...` : user.clientName}
            </Typography>
            <Typography variant="body2">{user.clientAccessLevel}</Typography>
            <Typography variant="body2">
              {fNumWithCommas(user.smsBalance)} units
              <IconButton size="small" onClick={() => dispatch(refreshSession())} color="inherit">
                <RefreshIcon fontSize="inherit" />
              </IconButton>
            </Typography>
          </Stack>
        </Stack>
        <IconButton onClick={onToggleNav} color="inherit">
          {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <List component="nav" aria-labelledby="nested-list-subheader" sx={{ p: 2 }}>
        {allowedRoutes.map((route) => (
          <div key={route.key}>
            <ListItemButton
              onClick={() => onClickMenu(route)}
              selected={currentLocation === route.path}
              sx={{ my: 0.5 }}
            >
              <ListItemIcon sx={{ minWidth: 50 }}>{route.icon}</ListItemIcon>
              <ListItemText primary={<Typography sx={{ fontWeight: 500 }}>{route.label}</Typography>} />
              {route.routes && expanded.includes(route.key) && <ExpandLess />}
              {route.routes && !expanded.includes(route.key) && <ExpandMore />}
            </ListItemButton>
            {route.routes && (
              <Collapse in={expanded.includes(route.key)}>
                {route.routes.map((subRoute) => (
                  <List key={subRoute.path} component="div" onClick={() => onClickMenu(subRoute)} disablePadding dense>
                    <ListItemButton sx={{ pl: 10 }} selected={currentLocation === subRoute.path}>
                      <ListItemText primary={subRoute.label} />
                    </ListItemButton>
                  </List>
                ))}
              </Collapse>
            )}
          </div>
        ))}
      </List>
      <Typography sx={{ mt: "auto", textAlign: "center", mb: 1 }} variant="body2">
        &copy;{currentYear} Onfon Media Group
        <br />v{process.env.REACT_APP_VERSION}
      </Typography>
    </Drawer>
  );
}

SideNav.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggleNav: PropTypes.func.isRequired,
  drawerWidth: PropTypes.number.isRequired,
  isMobile: PropTypes.bool,
};

SideNav.defaultProps = {
  isMobile: false,
};

export default SideNav;
