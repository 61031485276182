/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import onfonSmsApi from "../api/onfonSmsApi";

const initialState = {
  groups: [],
  users: [],
  smppConnectors: [],
  httpConnectors: [],
  genericConnectors: [],
  jasminFilters: [],
  jasminFilterTypes: [],
  moRoutes: [],
  mtRoutes: [],
};

const jasminSlice = createSlice({
  name: "jasmin",
  initialState,
  reducers: {
    setGroups(state, action) {
      state.groups = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setSmppConnectors(state, action) {
      state.smppConnectors = action.payload;
    },
    setHttpConnectors(state, action) {
      state.httpConnectors = action.payload;
    },
    setGenericConnectors(state, action) {
      state.genericConnectors = action.payload;
    },
    setJasminFilters(state, action) {
      state.jasminFilters = action.payload;
    },
    setJasminFilterTypes(state, action) {
      state.jasminFilterTypes = action.payload;
    },
    setMoRoutes(state, action) {
      state.moRoutes = action.payload;
    },
    setMtRoutes(state, action) {
      state.mtRoutes = action.payload;
    },
  },
});

export const {
  setGroups,
  setUsers,
  setSmppConnectors,
  setHttpConnectors,
  setGenericConnectors,
  setJasminFilters,
  setJasminFilterTypes,
  setMoRoutes,
  setMtRoutes,
} = jasminSlice.actions;

export const fetchJasminGroups = () => async (dispatch) => {
  const response = await onfonSmsApi.jasmin.groups.fetchAll();
  const { jasminGroups } = response.data;
  dispatch(setGroups(jasminGroups));
};

export const addJasminGroup = (data) => async (dispatch) => {
  await onfonSmsApi.jasmin.groups.create(data);
  dispatch(fetchJasminGroups());
};

export const fetchJasminUsers = () => async (dispatch) => {
  const response = await onfonSmsApi.jasmin.users.fetchAll();
  const { smppUsers } = response.data;
  dispatch(setUsers(smppUsers));
};

export const addJasminUser = (data) => async (dispatch) => {
  await onfonSmsApi.jasmin.users.create(data);
  dispatch(fetchJasminUsers());
};

export const fetchSmppConnectors = () => async (dispatch) => {
  const response = await onfonSmsApi.jasmin.smpp.fetchAll();
  const { jasminSmppConnectors } = response.data;
  dispatch(setSmppConnectors(jasminSmppConnectors));
};

export const addSmppConnector = (data) => async (dispatch) => {
  try {
    await onfonSmsApi.jasmin.smpp.create(data);
    dispatch(fetchSmppConnectors());
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error?.response.data);
  }
};

export const fetchHttpConnectors = () => async (dispatch) => {
  const response = await onfonSmsApi.jasmin.http.fetchAll();
  const { httpConnectors } = response.data;
  dispatch(setHttpConnectors(httpConnectors));
};

export const addHttpConnector = (data) => async (dispatch) => {
  await onfonSmsApi.jasmin.http.create(data);
  dispatch(fetchHttpConnectors());
};

export const fetchGenericConnectors = () => async (dispatch) => {
  const response = await onfonSmsApi.jasmin.generic.fetchAll();
  const { genericConnectors } = response.data;
  dispatch(setGenericConnectors(genericConnectors));
};

export const addGenericConnector = (data) => async (dispatch) => {
  await onfonSmsApi.jasmin.generic.create(data);
  dispatch(fetchGenericConnectors());
};

export const fetchJasminFilters = () => async (dispatch) => {
  const response = await onfonSmsApi.jasmin.filter.fetchAll();
  const { filters } = response.data;
  dispatch(setJasminFilters(filters));
};

export const fetchJasminFilterTypes = () => async (dispatch) => {
  const response = await onfonSmsApi.jasmin.filter.fetchFilterTypes();
  const { fields } = response.data;
  dispatch(setJasminFilterTypes(fields));
};

export const addJasminFilter = (data) => async (dispatch) => {
  await onfonSmsApi.jasmin.filter.create(data);
  dispatch(fetchJasminFilters());
};

export const fetchJasminMoRoutes = () => async (dispatch) => {
  const response = await onfonSmsApi.jasmin.mo.fetchAll();
  const { routes } = response.data;
  dispatch(setMoRoutes(routes));
};

export const fetchJasminMtRoutes = () => async (dispatch) => {
  const response = await onfonSmsApi.jasmin.mt.fetchAll();
  const { routes } = response.data;
  dispatch(setMtRoutes(routes));
};

export const addJasminMoRoute = (data) => async (dispatch) => {
  await onfonSmsApi.jasmin.mo.create(data);
  dispatch(fetchJasminMoRoutes());
};

export default jasminSlice.reducer;
