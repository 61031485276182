/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from "react";
import { AppBar as muiAppBar, Box, IconButton, styled, Toolbar } from "@mui/material";
import { Outlet } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import SideNav from "./SideNav";
import AvatarMenu from "./components/AvatarMenu";
import PortalLogo from "../components/PortalLogo";

const drawerWidth = 280;
const toolbarHeight = 70;
const isMobile = window.screen.width < 992;

const AppBar = styled(muiAppBar, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  backgroundColor: theme.palette.background.appBar,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(!isMobile &&
    open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
}));

const MainContent = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginTop: `${toolbarHeight + 15}px`,
  ...(!isMobile &&
    open && {
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
}));

function DashboardLayout() {
  const [open, setOpen] = useState(window.innerWidth > drawerWidth * 3);

  return (
    <Box>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <PortalLogo height={30} />
          <AvatarMenu />
        </Toolbar>
      </AppBar>
      <SideNav open={open} onToggleNav={() => setOpen(!open)} drawerWidth={drawerWidth} isMobile={isMobile} />
      <MainContent open={open}>
        <Outlet />
      </MainContent>
    </Box>
  );
}

export default DashboardLayout;
