import { cyan, green, grey, lime, orange, red } from "@mui/material/colors";

const getOidcStorageKey = () => {
  const authSettings = {
    auth_server: "betasms",
    client_id: "frontend",
  };
  return `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
};

const getOidcInfo = () => {
  const key = getOidcStorageKey();
  const oidc = JSON.parse(localStorage.getItem(key)) || {};
  return oidc;
};

export const setToken = (token) => {
  const oidc = getOidcInfo();
  if (oidc) {
    oidc.token = token;
    localStorage.setItem(getOidcStorageKey(), JSON.stringify(oidc));
  }
};

export const getToken = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc.token;
  }
  return null;
};

export const setUserDetails = (userDetails) => {
  const oidc = getOidcInfo();
  if (oidc) {
    oidc.user_details = userDetails;
    localStorage.setItem(getOidcStorageKey(), JSON.stringify(oidc));
  }
};

export const getUserDetails = () => {
  const oidc = getOidcInfo();
  if (oidc) {
    return oidc.user_details;
  }
  return {};
};

export const setThemeName = (themeName) => {
  const oidc = getOidcInfo();
  if (oidc) {
    oidc.themeName = themeName;
    localStorage.setItem(getOidcStorageKey(), JSON.stringify(oidc));
  }
};

export const getThemeName = () => {
  const oidc = getOidcInfo();
  return oidc?.themeName || "default";
};

export const setPreviousUrl = (url) => localStorage.setItem("url", url);

export const getPreviousUrl = () => {
  const previousUrl = localStorage.getItem("url");
  localStorage.removeItem("url");
  return previousUrl;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  const words = string.split(" ");
  for (let i = 0; i < words.length; i += 1) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(" ");
};

export const getStatusColor = (status) => {
  const successStatuses = ["success", "ACTIVE", "APPROVED", "COMPLETED", "SUCCESS"];
  const errorStatuses = ["failed", "FAILED", "INACTIVE"];
  const warningStatuses = ["PENDING", "PROCESSING", "INVITED"];
  if (successStatuses.includes(status)) return "success";
  if (errorStatuses.includes(status)) return "error";
  if (warningStatuses.includes(status)) return "warning";
  return "default";
};

export const getChartColor = (operatorName, colorType) => {
  switch (operatorName) {
    case "SAFARICOM":
    case "SAFCOM":
      return colorType === "main" ? green[500] : green[500];
    case "AIRTEL":
      return colorType === "main" ? red[700] : red[700];
    case "TELKOM":
      return colorType === "main" ? cyan[400] : cyan[400];
    case "FAIBA":
      return colorType === "main" ? lime[400] : lime[400];
    case "Submitted":
      return colorType === "main" ? orange[200] : orange[600];
    case "DeliveredToTerminal":
    case "DELIVRD":
      return colorType === "main" ? green[200] : green[600];
    case "DeliveryImpossible":
    case "EXPIRED":
    case "UNDELIV":
    case "SenderName Blacklisted":
    case "AbsentSubscriber":
      return colorType === "main" ? red[200] : red[600];
    default:
      return colorType === "main" ? grey[400] : grey[800];
  }
};
