import { format } from "date-fns";

export function fShortDate(val) {
  return format(new Date(val), "eee, dd-MMM");
}

export function fNormDate(val) {
  if (!val) return "";
  return format(new Date(val), "yyy-MM-dd HH:mm");
}

export function fDateOnly(val) {
  if (!val) return "";
  return format(new Date(val), "yyy-MM-dd");
}

export function fFancyDate(val) {
  if (!val) return "";
  return format(new Date(val), "eee, dd MMM yyy - hh:mm a");
}

export function fNumWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function fCamelToNorm(val) {
  if (!val) return "";
  const newVal = val
    // insert a space before all caps
    .replace(/([A-Z])/g, " $1")
    // uppercase the first character
    .replace(/^./, (str) => str.toUpperCase());
  return newVal;
}

export function fUtcTimestamp(val) {
  if (!val) return "";
  const date = new Date(val);
  return Math.floor(date.getTime() / 1000);
}
