/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import onfonSmsApi from "../api/onfonSmsApi";
import { getUserDetails, setToken, setUserDetails } from "../utils/helpers";

const initialState = {
  user: null,
  isInitialized: false,
  errorMessage: null,
  registeredUser: null,
  tempJwt: null,
  clients: [],
  host: null,
};

const baseUrl =
  process.env.NODE_ENV === "development" ? process.env.REACT_APP_HOST_URL : process.env.REACT_APP_HOST_URL_LIVE;

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isInitialized = true;
    },
    setError: (state, action) => {
      state.errorMessage = action.payload;
    },
    setRegisteredUser: (state, action) => {
      state.registeredUser = action.payload;
    },
    setTempJwt: (state, action) => {
      state.tempJwt = action.payload;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setHostDetails: (state, action) => {
      state.host = action.payload;
    },
  },
});

export const { setUser, setError, setRegisteredUser, setTempJwt, setClients, setHostDetails } = authSlice.actions;

export const registerClient = (data) => async (dispatch) => {
  try {
    dispatch(setError(null));
    const response = await onfonSmsApi.user.register(data);
    dispatch(setRegisteredUser(response.data));
    return Promise.resolve();
  } catch (error) {
    const { message } = error.response.data;
    dispatch(setError(message));
    return Promise.reject(error);
  }
};

export const joinClient = (data, token) => async (dispatch) => {
  try {
    dispatch(setError(null));
    const response = await axios.post(`${baseUrl}/v1/joinUser`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(setRegisteredUser(response.data));
    return Promise.resolve();
  } catch (error) {
    const { message } = error.response.data;
    dispatch(setError(message));
    return Promise.reject(error);
  }
};

export const refreshSession = () => async (dispatch) => {
  try {
    const { refreshToken, clientId } = getUserDetails();
    const response = await onfonSmsApi.user.refreshSession({ refreshToken, clientId });
    const { refreshToken: nRefreshToken, token } = response.data;
    setUserDetails({ clientId, refreshToken: nRefreshToken });
    setToken(token);
    dispatch(setUser(response.data.client));
    return Promise.resolve();
  } catch (error) {
    setUserDetails({});
    setToken(null);
    return Promise.reject(error?.response.data);
  }
};

export const requestAccountActivation = (data) => async (dispatch) => {
  try {
    dispatch(setError(null));
    const response = await onfonSmsApi.user.requestAccountActivation(data);
    const { tempJwt } = response.data;
    dispatch(setTempJwt(tempJwt));
    return Promise.resolve();
  } catch (error) {
    const { message } = error.response.data;
    dispatch(setError(message));
    return Promise.reject(error.response.data);
  }
};

export const activateAccount = (data) => async (dispatch, getState) => {
  dispatch(setError(null));
  await axios.post(`${baseUrl}/v1/activateAccountOTP`, data, {
    headers: { Authorization: `Bearer ${getState().auth.tempJwt}` },
  });
  dispatch(setTempJwt(null));
  dispatch(setRegisteredUser(null));
};

export const fetchClients = () => async (dispatch) => {
  const response = await onfonSmsApi.user.fetchClients();
  const { clients } = response.data;
  dispatch(setClients(clients));
};

export const fetchClient = (id) => async () => {
  try {
    const response = await onfonSmsApi.user.fetchClient(id);
    return response.data;
  } catch (error) {
    return Promise.reject(error?.response.data);
  }
};

export const inviteClient = (data) => async (dispatch) => {
  try {
    await onfonSmsApi.user.inviteClient(data);
    dispatch(fetchClients());
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error?.response.data);
  }
};

export const updateClientInfo = (id, data) => async (dispatch) => {
  try {
    await onfonSmsApi.user.editClient(id, data);
    dispatch(refreshSession());
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error?.response?.data);
  }
};

export const changePassword = (data) => async (dispatch) => {
  try {
    await onfonSmsApi.user.changePassword(data);
    await dispatch(refreshSession());
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error?.response?.data);
  }
};

export const fetchHostDetails = () => async (dispatch) => {
  try {
    const { data } = await onfonSmsApi.brand.getHostBranding();
    dispatch(setHostDetails(data));
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error?.response?.data);
  }
};

export default authSlice.reducer;
