import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import campaignReducer from "./campaignSlice";
import jasminReducer from "./jasminSlice";
import purchaseReducer from "./purchaseSlice";

const appReducer = combineReducers({
  auth: authReducer,
  campaign: campaignReducer,
  jasmin: jasminReducer,
  purchase: purchaseReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/setUser" && !action.payload) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
